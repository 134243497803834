import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/es/Typography/Typography";
import * as firebase from "firebase";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import {Card, List, ListItem, ListItemText} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
});


class Meets extends Component {

  constructor(props) {
    super(props);
    this.state = {showLoading: true, meets: []};
  }

  componentDidMount() {
    firebase.firestore().collection('/meet').onSnapshot((snapshot => {
      console.log("Firestore collection onSnapshot");
      let meets = [];
      snapshot.forEach(function (doc) {
        meets.push(doc.data());
      });
      meets.sort(function (a, b) {
        if (a.start_date < b.start_date) return 1;
        if (a.start_date > b.start_date) return -1;
        return 0;
      });
      console.log("Firestore collection onSnapshot: ", meets);
      this.setState({showLoading: false, meets: meets});
    }));
  }

  render() {
    const {classes} = this.props;
    return (
      <div className='meets'>
        <div className={classes.layout}>
          <Typography variant="h6">Meets</Typography>
          <br />
          <Button variant="contained" href="/">
             Back
          </Button><br />
          <br/>
          {this.renderList()}
        </div>
      </div>
    );
  }

  renderList() {
    if (this.state.showLoading) {
      return (<CircularProgress/>);
    }

    let list = [];
    this.state.meets.forEach((meet) => {
      list.push(
        <ListItem button component="a" href={"/meet/" + meet.id}>
          <ListItemText primary={meet.title}/>
        </ListItem>
      );
    });
    return (
      <Card>
        <List>
          {list}
        </List>
      </Card>
    );
  }
}

export default withStyles(styles)(Meets);
