import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/es/Typography/Typography";
import * as firebase from "firebase";
import Button from "@material-ui/core/Button";
import QrReader from 'react-qr-reader'
import {CircularProgress} from "@material-ui/core";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  meetImage: {
    maxWidth: '100%',
    maxHeight: '400px',
  }
});


class QrScan extends Component {

  constructor(props) {
    super(props);
    let codeFromLink = props.match.params.code;
    if (codeFromLink != null) {
      //From external QR-scanner
      this.state = {
        showLoading: false,
        showScanner: false,
        codeFromLink: codeFromLink,
        checkingQr: true,
        checkingQrError: false,
        checkingQrIncorrect: false,
        qrCorrect: false,
        meet: {poster: null, link: '#'},
        meetno: props.match.params.no,
        qrResult: null
      };
      this.checkCode(codeFromLink);
    } else {
      //Time to scan
      this.state = {
        showLoading: true,
        showScanner: true,
        codeFromLink: codeFromLink,
        checkingQr: false,
        checkingQrError: false,
        checkingQrIncorrect: false,
        qrCorrect: false,
        meet: {poster: null, link: '#'},
        meetno: props.match.params.no,
        qrResult: null
      };
    }
  }

  componentDidMount() {
    firebase.firestore().doc('/meet/' + this.state.meetno).onSnapshot((snapshot => {
      console.log("Firestore doc onSnapshot");
      this.setState({showLoading: false, meet: snapshot.data()});
    }));
  }

  render() {
    const {classes} = this.props;
    return (
      <div className='meet'>
        <div className={classes.layout}>
          <Typography variant="h6">QR Scanner (Meet {this.state.meetno})</Typography>
          <Button variant="contained" href={"/meet/" + this.state.meetno + "/qr"}>
             Back
          </Button>
          <br/>
          <Typography>
            Werkt de onderstaande QR-scanner niet? Kan gebeuren, is geen probleem.<br />
            Download een QR-scanner app vanuit de Store, dat werkt ook.<br />
            Gebruik je een Android telefoon? Klik dan <a href="https://play.google.com/store/apps/details?id=com.google.zxing.client.android">hier</a> om een QR-scanner te installeren.<br />
            <br />
            Doesn't the QR-scanner below work? Happens, is not a problem.<br />
            Download a QR-scanner app from the Store, that'll work just fine.<br />
            Are you on an Android phone? Click <a href="https://play.google.com/store/apps/details?id=com.google.zxing.client.android">here</a> to install a scanner app.
          </Typography>
          <br/>
          {(this.state.showLoading) ? <Fragment><CircularProgress/><br/></Fragment> : ''}
          {(this.state.checkingQrError) ? <Typography variant="h6">Error: Scan again</Typography> : ''}
          {(this.state.qrCorrect) ?
            <Typography variant="h6">QR-code correct! Klik op 'back' om de quiz-vraag te beantwoorden!<br />QR-code is correct! Press 'back' to answer the question!</Typography> : ''}
          {(this.state.checkingQrIncorrect) ? <Typography variant="h6">QR-code incorrect!</Typography> : ''}
          {(this.state.checkingQr) ?
            <Fragment><Typography variant="h6"><CircularProgress/> QR-code checking...</Typography></Fragment> : ''}
          {(this.state.showScanner) ? <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{width: '100%'}}
          /> : ''}
        </div>
      </div>
    );
  }


  handleScan = data => {
    if (data) {
      this.setState({
        qrResult: data,
        checkingQrError: false
      });

      this.checkUrl(data);
    }
  };
  handleError = err => {
    console.error(err)
  };

  checkUrl = url => {
    //Check if it's what we're looking for
    let baseUrl = "https://pwa.bronies.nl/meet/24/qr/";
    if (!url.startsWith(baseUrl)) {
      return;
    }
    let code = url.substring(34); //cut off baseUrl
    this.checkCode(code);
  };

  checkCode = code => {
    this.setState({checkingQr: true});
    //Check using Firebase function
    let scanQr = firebase.functions().httpsCallable("scanQr");
    scanQr({meetno: this.state.meetno, qrCode: code})
      .then((result) => {
        if (result.data.success == null || result.data.success !== true) {
          this.setState({checkingQrIncorrect: true});
        } else if (result.data.success) {
          this.setState({qrCorrect: true});
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({checkingQrError: true});
        debugger;
      })
      .finally(() => {
        this.setState({checkingQr: false});
      });
  };

}

export default withStyles(styles)(QrScan);
