import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/es/Typography/Typography";
import * as firebase from "firebase";
import {StyledFirebaseAuth} from "react-firebaseui";
import {Button} from "@material-ui/core";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
});


class Login extends Component {

  ui = null;

  constructor(props) {
    super(props);
    this.state = {showLoading: false};
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(() => {
      this.setState(this.state)
    });
  }

  uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      }
    },
    signInFlow: 'popup',
    signInSuccessUrl: '//pwa.bronies.nl/',
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: ['profile', 'email'],
        prompt: 'select_account'
      },
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        scopes: ['public_profile', 'email']
      },
      {
        provider: firebase.auth.TwitterAuthProvider.PROVIDER_ID
      }
    ],
    tosUrl: '/tos.html'
  };

  render() {
    const {classes} = this.props;
    return (
      <div className='meet'>
        <div className={classes.layout}>
          <Typography variant="h6">Login</Typography>
          <br/>
          <Button variant="contained" href={"/"}>
             Back
          </Button><br/>
          <br/>
          <Typography>
            Inloggen is noodzakelijk als er informatie moet worden opgeslagen
            <br/>
            (zoals bij het meedoen met een quiz! Je antwoorden moeten ergens worden opgeslagen!)
            <br/><br/>
            Je kan inloggen met een bestaand Google, Facebook of Twitter account,
            of je kan een nieuw account maken met emailadres en wachtwoord.
          </Typography>
          {(firebase.auth().currentUser != null) ? (
            <Typography variant="h6">Je bent al ingelogd!</Typography>
          ) : (
            <span>
              <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
            </span>
          )}
        </div>
      </div>
    );
  }


}

export default withStyles(styles)(Login);
