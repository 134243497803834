import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/es/Typography/Typography";
import * as firebase from "firebase";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  meetImage: {
    maxWidth: '100%',
    maxHeight: '400px',
  }
});


class Meet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoading: true,
      meet: {poster: null, link: '#', posters: []},
      currentPosterIndex: 0,
      meetno: props.match.params.no
    };
  }

  componentDidMount() {
    firebase.firestore().doc('/meet/' + this.state.meetno).onSnapshot((snapshot => {
      console.log("Firestore doc onSnapshot");
      this.setState({showLoading: false, meet: snapshot.data()});
    }));
  }

  render() {
    const {classes} = this.props;
    return (
      <div className='meet'>
        <div className={classes.layout}>
          <Typography variant="h6">{this.state.meet.title}</Typography>
          <br/>
          <Button variant="contained" href={"/meets"}>
             Back
          </Button><br/>
          <br/>
          {this.renderPoster()}
          <br/><br/>
          <Button variant="contained" href={this.state.meet.link}>Meer info</Button>
        </div>
      </div>
    );
  }

  renderPoster() {
    const {classes} = this.props;
    if (this.state.meet.posters != undefined && this.state.meet.posters.length > 0) {
      return (
        <Fragment>
          <img src={this.state.meet.posters[this.state.currentPosterIndex]} alt="Meet" className={classes.meetImage}/>
          <br/>
          <Button variant="contained" onClick={() => {
            this.setState({currentPosterIndex: (this.state.currentPosterIndex + 1) % this.state.meet.posters.length}
            )
          }}>
            Volgende poster
          </Button><br/>
        </Fragment>
      );
    }
    if (this.state.meet.poster != null) {
      return (<img src={this.state.meet.poster} alt="Meet" className={classes.meetImage}/>);
    }
    return (<Fragment/>);
  }

  renderDetailInfo() {
    const {classes} = this.props;
    //TODO: detailed information;
    //For example: show start and end date
    //And maybe a Map for the location
    return (<Fragment />);
  }

}

export default withStyles(styles)(Meet);
