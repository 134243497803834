import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/es/Typography/Typography";
import {Button, Card, Divider, InputLabel, List, ListItemText, Select} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import * as firebase from "firebase/app";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  textField: {
    width: 200,
  },
});


class AddMeet extends Component {

  constructor(props) {
    super(props);
    this.state = {showLoading: true, meets: []};
  }

  componentDidMount() {
    firebase.firestore().collection('/meet').onSnapshot((snapshot => {
      console.log("Firestore collection onSnapshot");
      let meets = [];
      snapshot.forEach(function (doc) {
        meets.push(doc.data());
      });
      meets.sort(function (a, b) {
        if (a.start_date < b.start_date) return 1;
        if (a.start_date > b.start_date) return -1;
        return 0;
      });
      console.log("Firestore collection onSnapshot: ", meets);
      this.setState({showLoading: false, meets: meets});
    }));
  }

  renderAddMeetForm(classes) {
    return (
      <div className='addMeet'>
        <br />
        <Typography variant="h6">Add Meet</Typography>

        <TextField
          required
          id="meet-id"
          label="id"
          defaultValue=""
          className={classes.textField}
          margin="normal"
        />&nbsp;
        <Typography>
          Required; <br />
          id van de meet.<br />
          Voor grote meets is dit het meet nummer, bijv:"28", voor cafe-meets ook bijv:"27.5".
          Voor camping meets is het "camping-" + jaartal, bijv: "camping-2022".
          Voor PCH is het "PCH-" + jaartal, bijv: "PCH-2022".</Typography>
        <br />

        <TextField
          required
          id="meet-title"
          label="title"
          defaultValue=""
          className={classes.textField}
          margin="normal"
        />&nbsp;
        <Typography>
          Required; <br />
          (Volledige) titel van de meet.<br />
          Voor grote meets is dit "Meet " + nummer ": " + thema, bijv: "Meet 28: The good old days".
          Voor cafe meets is dit "Cafemeet" + nummer", bijv: "Cafemeet 27.5".
          Voor camping meets, zie grote meets.
          Voor PCH is het "PonyCon Holland " + jaartal, bijv: "PonyCon Holland 2022".
        </Typography>

        <TextField
          required
          id="meet-link"
          label="link"
          defaultValue=""
          className={classes.textField}
          margin="normal"
        />&nbsp;
        <Typography>
          Required; <br />
          Link naar meer informatie. <br />
          Voor BroniesNL meets, is dit een link naar de forum post. Voor PonyCon Holland een link naar hun website.
        </Typography>

        <TextField
          required
          id="meet-poster"
          label="poster"
          defaultValue=""
          className={classes.textField}
          margin="normal"
        />&nbsp;
        <Typography>
          Required; <br />
          Link naar poster afbeelding. <br />
          Als de afbeelding is opgenomen in de broncode van deze PWA, kan je een lokale url gebruiken.
          (Anders uiteraard volledige URL, incl. https://)
          Afbeeldingen direct hot-linken vanuit het forum wordt afgeraden.
          Deze zijn niet in volledige resolutie als de gebruiker niet is ingelogd in het forum.
        </Typography>

        <br />
        <InputLabel id="meet-type-select-label">meet type</InputLabel>
        <br />
        <Select
          labelId="meet-type-select-label"
          id="meet-type-select"
          value="meet"
          label="meet type"
        >
          <MenuItem value="meet">meet</MenuItem>
          <MenuItem value="cafemeet">cafemeet</MenuItem>
          <MenuItem value="camping">camping</MenuItem>
          <MenuItem value="conventions">conventions</MenuItem>
        </Select>&nbsp;
        <Typography>
          Required; <br />
          Type bijeenkomst. <br />
          Zou voor zich moeten spreken.
        </Typography>

        <br /><br />
        <Typography variant="h6">
          Andere velden graag invullen via de Firebase console!!!
        </Typography>

        <br />
        <Button variant="contained">Save</Button>
      </div>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <div className={classes.layout}>
          {this.renderAddMeetForm(classes)}
          <hr/>
          <div className='meets'>
            <Typography variant="h6">Meets</Typography>
            <br/>
            <Button variant="contained" href="/">
               Back
            </Button><br/>
            <br/>
            {this.renderList()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderList() {
    if (this.state.showLoading) {
      return (<CircularProgress/>);
    }

    let list = [];
    this.state.meets.forEach((meet) => {
      list.push(
        <ListItem button component="a" href={"/meet/" + meet.id}>
          <ListItemText primary={meet.title}/>
        </ListItem>
      );
    });
    return (
      <Card>
        <List>
          {list}
        </List>
      </Card>
    );
  }
}


export default withStyles(styles)(AddMeet);
