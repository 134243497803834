import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/es/Typography/Typography";
import * as firebase from "firebase";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  meetImage: {
    maxWidth: '100%',
    maxHeight: '400px',
  }
});


class QrCheck2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoading: true,
      meet: {poster: null, link: '#', qrCodesCount: 0},
      meetno: props.match.params.no,
      users: [],
      questions: []
    };
  }

  componentDidMount() {
    firebase.firestore().doc('/meet/' + this.state.meetno).get().then((snapshot => {
      console.log("Firestore doc onSnapshot");
      this.setState({showLoading: false, meet: snapshot.data()});
    }));

    //Get questions
    firebase.firestore().collection(`qr_hunt/${this.state.meetno}/questions/`)
      .orderBy('index')
      .get().then((snapshot => {
      let questions = [];
      snapshot.forEach((question) => {
        questions.push(question.data());
      });
      this.setState({questions: questions});
    }));

    //Get users
    firebase.firestore().collection('/users/').get()
      .then((snapshot => {
        let users = [];
        snapshot.forEach((userDoc) => {
          let user = userDoc.data();

          let pFound = firebase.firestore().collection(`users/${user.uid}/meets/${this.state.meetno}/found`).get();
          pFound.then((snapshot => {
            let found = [];
            snapshot.forEach(docSnapshot => {
              found[docSnapshot.id] = docSnapshot.data();
            });
            if (found.length > 0) {
              let temp = {};
              temp['found_' + user.uid] = found;
              this.setState(temp);
            }
          }));

          let pAnswers = firebase.firestore().collection(`users/${user.uid}/meets/${this.state.meetno}/answers`).get();
          pAnswers.then((snapshot => {
            let user = userDoc.data();
            let answers = [];
            snapshot.forEach(docSnapshot => {
              answers[docSnapshot.id] = docSnapshot.data();
            });
            if (answers.length > 0) {
              let temp = {};
              temp['answers_' + user.uid] = answers;
              users.push(userDoc.data()); //IMPORTANT
              this.setState(temp);
            }
          }));
        });
        this.setState({users: users});
      }));

  }

  render() {
    const {classes} = this.props;
    return (
      <div className='meet'>
        <div className={classes.layout}>
          <Typography variant="h6">QR Hunt van Meet {this.state.meetno}</Typography>
          <br/>
          <Button variant="contained" href={"/meet/" + this.state.meetno + "/qr"}>
            Back
          </Button>
          <br/><br/>
          <Typography variant="h5">
            QR-hunt nakijken.
          </Typography><br/>
          {(!this.state.showLoading) ? this.renderCheck() : (<CircularProgress/>)}
          <br/><br/>
          {this.renderStats()}
        </div>
      </div>
    );
  }

  renderCheck() {
    //Per player answers, with Correct and Incorrect buttons.
    let renderUsers = [];
    let users = this.state.users;
    users.forEach((user) => {
      let numOfAnswers = 0;
      let numOfCorrect = 0;
      if (this.state['answers_' + user.uid] !== undefined &&
        this.state['answers_' + user.uid] != null) {
        let userAnswers = [];
        let answerTimeTotal = 0;
        this.state['answers_' + user.uid].forEach((answer, index) => {
          numOfAnswers++;
          let time = -1;
          if (answer.end_time != null) {
            time = answer.end_time.seconds - answer.start_time.seconds;
          }
          if (time !== -1) {
            answerTimeTotal += time;
          }
          let isCorrect = (this.state.questions[index].answers[0] === answer.answer)
          if (isCorrect) {
            numOfCorrect++;
          }
          let temp = (
            <Fragment>
              <Typography><strong>{(index + 1)} - {this.state.questions[index].question}:</strong></Typography>
              <Typography>
                <u>{answer.answer}</u>
                &nbsp;
                <b>{isCorrect ? "Correct!" : "Incorrect.."}</b>
                &nbsp;
                (Time: {time} seconds)
              </Typography>
              <br/>
            </Fragment>
          );
          userAnswers.push(temp);
        });

        let amountOfQrsFound = 0;
        for (let i = 0; i < this.state['found_' + user.uid].length; i++) {
          if (this.state['found_' + user.uid][i] != null && this.state['found_' + user.uid][i] !== undefined) {
            amountOfQrsFound += 1;
          }
        }


        let temp = (<Fragment>
          <Typography variant="h6">{user.nickname}<br/>(gevonden: {amountOfQrsFound}; beantwoord: {numOfAnswers}; correct: {numOfCorrect}; totaal tijd: {answerTimeTotal})</Typography>
          <small>uid={user.uid}, email={user.email}, createdAt={this.timestampToString(user.createdAt)}</small>
          {userAnswers}
          <hr/>
        </Fragment>);
        if (numOfAnswers > 0) {
          renderUsers.push(temp);
        }
      }
    });

    return renderUsers;
  }

  timestampToString(timestamp) {
    if (timestamp == undefined) {
      return ""
    }
    //https://stackoverflow.com/a/5417197/1398449
    let date = new Date();
    date.setTime(timestamp.seconds * 1000)
    return date.toUTCString()
  }

  renderStats() {
    let numPlayersWithAtLeast1Found = 0;
    let numPlayersThatFoundAll = 0;
    let playerThatFoundAllFastestFinderPlayer = null;
    let playerThatFoundAllFastestFinderTime = null;
    let numPlayersWithAtLeast1Answered = 0;
    let numPlayersThatAnsweredAll = 0;
    let playerThatAnsweredAllFastestPlayer = null;
    let playerThatAnsweredAllFastestTime = null;
    this.state.users.forEach((user) => { //MAIN LOOP <-- <-- <-- <-- !!!
      //FOUND
      if (this.state['found_' + user.uid] !== undefined &&
        this.state['found_' + user.uid] != null &&
        this.state['found_' + user.uid].length > 0) {
        //This player found at least one
        numPlayersWithAtLeast1Found++;

        //<editor-fold desc="Players who found all QR-codes">
        if (this.state['found_' + user.uid].length === this.state.meet.qrcode_count) {
          //This player found all of them
          numPlayersThatFoundAll++;

          //Check latest find
          let timeFirstQrCode = null;
          let timeLastQrCode = null;
          this.state['found_' + user.uid].forEach(find => {
            if (timeFirstQrCode == null || timeFirstQrCode > find.time) {
              timeFirstQrCode = find.time;
            }
            if (timeLastQrCode == null || timeLastQrCode < find.time) {
              timeLastQrCode = find.time;
            }
          });
          //Check if fastest finder (player)
          if (playerThatFoundAllFastestFinderTime == null ||
            playerThatFoundAllFastestFinderTime > timeLastQrCode) {
            playerThatFoundAllFastestFinderTime = timeLastQrCode;
            playerThatFoundAllFastestFinderPlayer = user.nickname; //user.uid;
          }
        }
        //</editor-fold>
      }

      //ANSWERED
      if (this.state['answers_' + user.uid] !== undefined &&
        this.state['answers_' + user.uid] != null &&
        this.state['answers_' + user.uid].length > 0) {
        //This player has answered at least one
        numPlayersWithAtLeast1Answered++;

        //<editor-fold desc="Players who answered all Questions">
        if (this.state['answers_' + user.uid] !== undefined &&
          this.state['answers_' + user.uid] != null &&
          this.state['answers_' + user.uid].length === this.state.meet.qrcode_count) {
          numPlayersThatAnsweredAll++;
        }

        if (this.state['answers_' + user.uid].length === this.state.meet.qrcode_count) {
          let timeFirstAnswer = null;
          let timeLastAnswer = null;
          this.state['answers_' + user.uid].forEach(answer => {
            if (timeFirstAnswer == null || timeFirstAnswer > answer.end_time) {
              timeFirstAnswer = answer.end_time;
            }
            if (timeLastAnswer == null || timeLastAnswer < answer.end_time) {
              timeLastAnswer = answer.end_time;
            }
          });
          //Check if fastest finder
          if (playerThatAnsweredAllFastestTime == null ||
            playerThatAnsweredAllFastestTime > timeLastAnswer) {
            playerThatAnsweredAllFastestTime = timeLastAnswer;
            playerThatAnsweredAllFastestPlayer = user.nickname; //user.uid;
          }
        }
        //</editor-fold>
      }
    });

    //Gemiddelde tijd per vraag
    //Per speler, gevonden en beantwoord

    return (
      <Fragment>
        <h3>Stats</h3>
        <Typography>Aantal Hunt-Spelers (minimaal 1 QR-code
          gevonden): {numPlayersWithAtLeast1Found}</Typography>
        <Typography>Aantal Spelers die alle QR-codes hebben gevonden: {numPlayersThatFoundAll}</Typography>
        <Typography>
          Eerste speler die alle QR-codes heeft gevonden:&nbsp;
          {(playerThatFoundAllFastestFinderPlayer != null) ? playerThatFoundAllFastestFinderPlayer : ''}&nbsp;
          ({playerThatFoundAllFastestFinderTime != null ? playerThatFoundAllFastestFinderTime.toDate().toLocaleString() : ''})
        </Typography>
        <hr/>
        <Typography>Aantal Quiz-Spelers (minimaal 1 vraag
          beantwoord): {numPlayersWithAtLeast1Answered}</Typography>
        <Typography>Aantal Spelers die alle vragen hebben beantwoord: {numPlayersThatAnsweredAll}</Typography>
        <Typography>
          Eerste speler die alle vragen heeft beantwoord:&nbsp;
          {(playerThatAnsweredAllFastestPlayer != null) ? playerThatAnsweredAllFastestPlayer : ''}&nbsp;
          ({playerThatAnsweredAllFastestTime != null ? playerThatAnsweredAllFastestTime.toDate().toLocaleString() : ''})
        </Typography>
        <hr/>
      </Fragment>
    );
  }

}

export default withStyles(styles)(QrCheck2);
