import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/es/Typography/Typography";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
});


class NotFound extends Component {

  constructor(props) {
    super(props);
    this.state = {showLoading: true, meets: []};
  }

  componentDidMount() {
    //firebase.firestore().collection('/meets').onSnapshot((snapshot => {}));
  }


  render() {
    const {classes} = this.props;
    return (
      <div className='info'>
        <div className={classes.layout}>
          <Typography variant="h6">404 page not found</Typography>
          Pad: {this.props.location.pathname} niet gevonden.
          <br/>
          <a href="/">Terug naar de hoofdpagina</a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(NotFound);
