import React, {Component, Fragment} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from '@material-ui/icons/AccountCircle';
import {withStyles} from "@material-ui/core/styles";
import * as firebase from "firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import favicon from '../images/favicon-512.png';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: '40px',
  }
};


class TopBar extends Component {

  state = {
    auth: true,
    anchorEl: null,
    dialogNicknameOpen: false
  };

  handleMenu = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleMenuClose = () => {
    this.setState({anchorEl: null});
  };

  handleDialogNicknameOpen = () => {
    this.setState({dialogNicknameOpen: true});
  };

  handleDialogNicknameSubmit = () => {
    let newNickname = document.getElementById('nickname').value;
    if (newNickname.length < 1) {
      return;
    }
    this.setState({showNicknameLoading: true});
    let changeNick = firebase.functions().httpsCallable('changeNickname');
    changeNick({nickname: newNickname})
      .then(() => {
        console.log("changed nickname");
        this.setState({showNicknameLoading: false});
      })
      .catch((error) => {
        console.log("changed nickname; error!");
        console.log(error);
        this.setState({showNicknameLoading: false});
      });
    this.handleDialogNicknameClose();
  };

  handleDialogNicknameClose = () => {
    this.setState({dialogNicknameOpen: false});
  };

  handleChangeNickname = () => {
    //open dialog to change nickname
    this.handleDialogNicknameOpen();
    this.handleMenuClose();
  };

  logout = () => {
    //go to logout screen
    this.handleMenuClose();
    let promise = firebase.auth().signOut();
    promise.then(() => {
      console.log('Logged out.');
      //this will reset state
      //TODO: Find better way to reset App-level state.
      window.location.reload();
    });
  };

  componentDidMount() {
    //add listeners
    //Auth
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.setState({auth: true}); //redraw
          //User (for nickname)
          firebase.firestore().doc('users/' + user.uid).onSnapshot((userDoc) => {
            if (userDoc.exists) {
              this.setState({user: userDoc.data()});
            }
          });
        } else {
          this.setState({auth: false}); //redraw
        }
      }
    );
  }

  render() {
    const {classes} = this.props;
    const {auth, anchorEl} = this.state;
    const menuOpen = Boolean(anchorEl);

    return (
      <div className='flexGrow'>
        <AppBar position="static">
          <Toolbar>
            <Fragment>
              <a href="/">
                <img src={favicon} alt="logo" className={classes.logo}/>
              </a>
              <Typography variant="h6" color="inherit" className={classes.grow}>
                BroniesNL
              </Typography>
            </Fragment>
            &nbsp;
            {this.state.auth ? (
              <Fragment>
                <Typography color="inherit" variant="h6">
                  {(this.state.user && this.state.user.nickname) ?
                    this.state.user.nickname : firebase.auth().currentUser ? firebase.auth().currentUser.displayName : ''}
                </Typography>
                <IconButton
                  aria-owns={menuOpen ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle/>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={menuOpen}
                  onClose={this.handleMenuClose}
                >
                  <MenuItem onClick={this.handleChangeNickname}>Change nickname</MenuItem>
                  <MenuItem onClick={this.logout}>Logout</MenuItem>
                </Menu>
              </Fragment>
            ) : <Button variant="contained" href="/login">Log in</Button>}
          </Toolbar>
        </AppBar>

        <Dialog
          open={this.state.dialogNicknameOpen}
          onClose={this.handleDialogNicknameClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change nickname</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Change nickname
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="nickname"
              label="Nickname"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogNicknameClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleDialogNicknameSubmit}
              color="primary"
              disabled={this.state.showNicknameLoading}
            >
              Submit
            </Button>
            <br/><br/>
            {(this.state.showNicknameLoading) ? <CircularProgress/> : ''}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(TopBar);
