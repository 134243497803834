import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/es/Typography/Typography";
import {Button, Card, Divider, ListItemText} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import * as firebase from "firebase/app";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
});


class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {showLoading: true, meets: []};
  }

  componentDidMount() {
    firebase.firestore().collection('/meet').onSnapshot((snapshot => {
      console.log("Firestore collection onSnapshot");
      let meets = [];
      snapshot.forEach(function (doc) {
        meets.push(doc.data());
      });
      meets.sort(function (a, b) {
        if (a.start_date < b.start_date) return 1;
        if (a.start_date > b.start_date) return -1;
        return 0;
      });
      console.log("Firestore collection onSnapshot: ", meets);
      this.setState({showLoading: false, meets: meets});
    }));
  }

  renderQrHuntButtonIfNeeded(){
    if (this.state.meets.length === 0) {
      //no meets, no button
      return ('');
    }

    //check if the most recent meet has a qr-hunt
    const meet = this.state.meets[0];
    if (meet.qrcode_count > 0) {
      const meetLink = "/meet/" + meet.id + "/qr";
      return (
        <Button variant="contained" href={meetLink}>
          {meet.title} - QR-hunt
        </Button>
      )
    }

    return ("");
  }


  render() {
    const {classes} = this.props;
    return (
      <div className='home'>
        <div className={classes.layout}>
          <Typography variant="h6">BroniesNL</Typography>
          <br/>

          {this.renderQrHuntButtonIfNeeded()}

          <br /><br />

          <Card>
            <ListItem button component="a" href="/meets">
              <ListItemText primary="Meets"/>
            </ListItem>
            <Divider />
            <ListItem button component="a" href="/info">
              <ListItemText primary="Info"/>
            </ListItem>
          </Card>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
