import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/es/Typography/Typography";
import * as firebase from "firebase";
import Button from "@material-ui/core/Button";
import {CircularProgress, FormControlLabel, Radio} from "@material-ui/core";
import RadioGroup from "@material-ui/core/es/RadioGroup/RadioGroup";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  textField: {
  },
  textFieldNumber: {
    width: 300
  },
  quizImage: {
    maxWidth: '100%',
    maxHeight: '400px',
  }
});


class QrQuiz extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoading: true,
      meet: {poster: null, link: '#'},
      meetno: props.match.params.no,
      qrIndex: props.match.params.nr,
      questionIndex: -1,
      question: null,
      answer: null,
      saved: false,
      saveError: null
    };
  }

  componentDidMount() {
    firebase.firestore().doc('/meet/' + this.state.meetno).onSnapshot((snapshot => {
      console.log("Firestore doc onSnapshot");
      this.setState({showLoading: false, meet: snapshot.data()});
    }));

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //Get the shuffle
        firebase.firestore().doc(`users/${user.uid}/meets/${this.state.meetno}`).onSnapshot((snapshot => {
          //Get the (user given) answer to the question behind this qr-code.
          let shuffle = snapshot.data().shuffle;
          let questionIndex = shuffle[this.state.qrIndex];
          this.setState({questionIndex: questionIndex});
          //Get matching answer
          firebase.firestore().doc(`users/${user.uid}/meets/${this.state.meetno}/answers/${questionIndex}`).onSnapshot(snapshot1 => {
            this.setState({answer: snapshot1.data()});
          });
        }));
      }
    });
  }

  render() {
    const {classes} = this.props;
    return (
      <div className='meet'>
        <div className={classes.layout}>
          <Typography variant="h6">QR Quiz - Meet {this.state.meetno} -
            QR-code {parseInt(this.state.qrIndex) + 1} </Typography>
          <Button variant="contained" href={"/meet/" + this.state.meetno + "/qr"}> Back</Button>
          <br/>
          {
            (firebase.auth().currentUser == null) ?
              <Fragment>
                <br/>
                <Typography>Het is noodzakelijk om ingelogd te zijn om mee te kunnen spelen!<br/>You need to be logged
                  in to play!</Typography>
                <br/>
                <Button variant="contained" href="/login">Login</Button>
              </Fragment>
              :
              <Fragment>
                {this.renderQuestion()}
              </Fragment>
          }
          <br/><br/>
        </div>
      </div>
    );
  }

  renderQuestion = () => {
    const {classes} = this.props;
    let answer = this.state.answer;
    let isAnswered = (answer != null && answer.end_time != null);
    if (this.state.question == null) {
      if (!isAnswered) {
        return (<Fragment>
          <br/>
          <Typography>Er wordt bijgehouden wie een vraag het snelste beantwoord, de tijd gaat in zodra je de vraag
            opent.<br/>We keep record of who answers the fastest, time starts when you open the question.</Typography>
          <br/>
          <Button variant="contained" onClick={this.openQuestion} disabled={this.state.showLoading}>Start</Button>
          {(this.state.showLoading ? <CircularProgress/> : '')}
        </Fragment>);
      } else {
        return (<Fragment>
          <br/>
          <Typography>Je hebt deze vraag al beantwoord, als je een nieuw antwoord opslaat, gaat dat van je tijd
            af!<br/>You've already answered this question, if you answer again, extra time is added!</Typography>
          <br/>
          <Button variant="contained" onClick={this.openQuestion} disabled={this.state.showLoading}>
            Start
          </Button>
          {(this.state.showLoading ? <CircularProgress/> : '')}
        </Fragment>);
      }
    }

    //prepare question
    let question = this.state.question;
    let showLoading = this.state.showLoading;
    let saved = this.state.saved;
    let answerComponent = (<Fragment/>);
    if (question.type === "radio") {
      let temp = [];
      let answersArray = question.answers;
      answersArray = answersArray.sort(() => Math.random() - 0.5);
      answersArray.forEach((qa) => {
        temp.push(<FormControlLabel value={qa} control={<Radio/>} disabled={showLoading || saved} label={qa}/>);
      });
      answerComponent = (
        <Fragment>
          <RadioGroup
            aria-label="Quiz answers"
            name="answer"
          >
            {temp}
          </RadioGroup>
          {(this.state.saved) ? (
            <Fragment><Typography variant="h6">Answer has been saved!</Typography><br/></Fragment>) : ''}
          {(this.state.saveError) ? (
            <Fragment><Typography variant="h6">Error: {this.state.saveError}</Typography><br/></Fragment>) : ''}
          <Button variant="contained" onClick={this.saveAnswer}
                  disabled={showLoading || saved || this.state.saveError != null}>Save</Button>
          {(this.state.showLoading ? <CircularProgress/> : '')}
        </Fragment>
      );
    }

    if (question.type === "open") {
      answerComponent = (
        <Fragment>
          <br />
          <TextField
            id="question-textarea"
            name="question-textarea"
            label="Answer"
            placeholder="Fill in answer here..."
            multiline
            className={classes.textField}
            margin="normal"
          />
          <br/>
          {(this.state.saved) ? (
            <Fragment><Typography variant="h6">Answer has been saved!</Typography><br/></Fragment>) : ''}
          {(this.state.saveError) ? (
            <Fragment><Typography variant="h6">Error: {this.state.saveError}</Typography><br/></Fragment>) : ''}
          <Button variant="contained" onClick={this.saveAnswer}
                  disabled={showLoading || saved || this.state.saveError != null}>Save</Button>
          {(this.state.showLoading ? <CircularProgress/> : '')}
        </Fragment>
      );
    }

    if (question.type === "order") {
      let temp = [];
      let count = 0;
      question.answers.forEach((qa) => {
        temp.push(
          <Fragment>
            <TextField
              id={"question-" + count}
              name={"question-" + count}
              label={qa}
              type="number"
              placeholder=""
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textFieldNumber}
              margin="normal"
            />
            <br/>
          </Fragment>
        );
        count++;
      });
      answerComponent = (
        <Fragment>
          <Typography>
            Geef de volgorde aan door de getallen 1 t/m {this.state.question.answers.length} in te vullen.<br />
            Tell the order, by filling the numbers 1 t/m {this.state.question.answers.length}.
          </Typography>
          <Fragment>
            {temp}
          </Fragment>

          {(this.state.saved) ? (
            <Fragment><Typography variant="h6">Answer has been saved!</Typography><br/></Fragment>) : ''}
          {(this.state.saveError) ? (
            <Fragment><Typography variant="h6">Error: {this.state.saveError}</Typography><br/></Fragment>) : ''}
          <Button variant="contained" onClick={this.saveAnswer}
                  disabled={showLoading || saved || this.state.saveError != null}>Save</Button>
          {(this.state.showLoading ? <CircularProgress/> : '')}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Typography variant="h6">{question.question}</Typography>
        {(question.image) ?
          <img src={question.image} alt="Quiz image" className={classes.quizImage}/> : ''
        }
        {answerComponent}
      </Fragment>
    );
  };

  openQuestion = () => {
    this.setState({showLoading: true});

    let openQuestion = firebase.functions().httpsCallable("openQuestion");
    openQuestion({meetno: this.state.meetno, qrIndex: this.state.qrIndex})
      .then((result) => {
        console.log(result);
        this.setState({question: result.data.question});
      })
      .catch((reason) => {
        console.log(reason);
        debugger;
      })
      .finally(() => {
        this.setState({showLoading: false});
      });
  };

  saveAnswer = () => {
    this.setState({showLoading: true});

    let answer = null;
    if (this.state.question.type === "radio") {
      answer = document.querySelector('input[name="answer"]:checked').value;
    }
    if (this.state.question.type === "open") {
      //answer = document.querySelector('input[name="question-textarea"]').value;
      answer = document.getElementById('question-textarea').value;
    }
    if (this.state.question.type === "order") {
      //get all textfields and concat.
      let temp = "";
      debugger;
      for (let a = 0; a < this.state.question.answers.length; a++){
        temp += document.getElementById('question-' + a).value;
        if (a < this.state.question.answers.length-1) {
          //no "," at the end
          temp += ",";
        }
      }
      answer = temp;
    }

    console.log(answer);

    let answerQuestion = firebase.functions().httpsCallable('answerQuestion');
    answerQuestion({
      meetno: this.state.meetno,
      qrIndex: this.state.qrIndex,
      questionIndex: this.state.questionIndex,
      answer: answer
    })
      .then(
        (result) => {
          console.log(result);
          //debugger;
          if (result.data.success) {
            this.setState({saved: true});
          }
          if (result.data.error) {
            this.setState({saveError: result.data.error});
          }
        }
      )
      .catch((reason) => {
        console.log(reason);
        debugger;
      })
      .finally(() => {
        this.setState({showLoading: false});
      });
  }

}

export default withStyles(styles)(QrQuiz);
