import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/es/Typography/Typography";
import * as firebase from "firebase";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  meetImage: {
    maxWidth: '100%',
    maxHeight: '400px',
  }
});


class QrPrint extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoading: true,
      meet: {poster: null, link: '#'},
      meetno: props.match.params.no,
      qrCodes: []
    };
  }

  componentDidMount() {
    firebase.firestore().doc('/meet/' + this.state.meetno).onSnapshot((snapshot => {
      console.log("Firestore doc onSnapshot");
      this.setState({showLoading: false, meet: snapshot.data()});
    }));

    firebase.firestore().collection('/qr_hunt/' + this.state.meetno + "/qr_codes").onSnapshot((snapshot => {
      console.log("Firestore collection onSnapshot");
      let qrCodes = [];
      snapshot.forEach(doc => {
        qrCodes.push(doc.data());
      });
      qrCodes.sort(function (a, b) {
        if (a.index < b.index) return -1;
        if (a.index > b.index) return 1;
        return 0;
      });
      this.setState({qrCodes: qrCodes});
    }));
  }

  render() {
    const {classes} = this.props;
    return (
      <div className='meet'>
        <div className={classes.layout}>
          <Typography variant="h6">QR Hunt van Meet {this.state.meetno}</Typography>
          <Button variant="contained" href={"/meet/" + this.state.meetno + "/qr"}>
             Back
          </Button>
          <p>
            QR-codes om te printen.
          </p>
          {this.renderListOfQrCodes()}
        </div>
      </div>
    );
  }

  renderListOfQrCodes() {
    let qrBase = "http://chart.apis.google.com/chart?cht=qr&chs=320x320&chld=L&choe=UTF-8&chl=";
    let urlBase = "https%3A%2F%2Fpwa.bronies.nl%2Fmeet%2F" + this.state.meetno + "%2Fqr%2F";
    let stuff = this.state.qrCodes.map(qr => {
      return (
        <Fragment>
          <br />
          <img src={qrBase + urlBase + qr.id} alt="QR"/>
          <br /><br />
          <Typography variant="h6">{qr.index} - {qr.id}</Typography>
          <br />
        </Fragment>
      );
    });

    return stuff;
  }

}

export default withStyles(styles)(QrPrint);
