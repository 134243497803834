import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/es/Typography/Typography";
import * as firebase from "firebase";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Card, List, ListItem, ListItemText} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Done from '@material-ui/icons/Done';
import {ModeComment, Search} from "@material-ui/icons";

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  meetImage: {
    maxWidth: '100%',
    maxHeight: '400px',
  }
});


class QrHunt extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoading: true,
      meet: {poster: null, link: '#', qrCodesCount: 0},
      meetno: props.match.params.no,
      found: null,
      answers: null,
      shuffle: null
    };
  }

  componentDidMount() {
    firebase.firestore().doc('/meet/' + this.state.meetno).onSnapshot((snapshot => {
      console.log("Firestore doc onSnapshot");
      this.setState({showLoading: false, meet: snapshot.data()});
    }));

    //Add found and answered listeners when auth is connected
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //found and answered
        ///users/UID/meets/24/found/QR_ID
        //Get Shuffle
        firebase.firestore().doc(`users/${user.uid}/meets/${this.state.meetno}`).onSnapshot((snapshot => {
          if (snapshot.exists) {
            this.setState({shuffle: snapshot.data().shuffle});
          }
        }));

        firebase.firestore().collection(`users/${user.uid}/meets/${this.state.meetno}/found`).onSnapshot((snapshot => {
          let found = [];
          snapshot.forEach(docSnapshot => {
            found[docSnapshot.id] = docSnapshot.data();
          });
          this.setState({found: found});
        }));
        firebase.firestore().collection(`users/${user.uid}/meets/${this.state.meetno}/answers`).onSnapshot((snapshot => {
          let answers = [];
          snapshot.forEach(docSnapshot => {
            answers[docSnapshot.id] = docSnapshot.data();
          });
          this.setState({answers: answers});
        }));
      }
    });
  }

  render() {
    const {classes} = this.props;
    return (
      <div className='meet'>
        <div className={classes.layout}>
          <Typography variant="h6">QR Hunt van {this.state.meet.title}</Typography>
          <br />
          <Button variant="contained" href={"/"}>
             Back
          </Button><br /><br />
          <Typography>
            Zoek de QR-codes en scan ze met je telefoon.
            Zodra je een QR-code hebt gescant krijg je een Quiz-vraag te zien.
            Beantwoord deze binnen de tijd!
            De speler die de meeste Quiz-vragen goed heeft beantwoord, heeft gewonnen!
            <br/>
            Succes met zoeken!!
            <br /><br />
            Search the hidden QR-codes and scan them with your phone.
            When you find a QR-code, you will be shown a Quiz-question.
            Answer this question as quick as possible.
            The player with the most correct answers wins the game!
            <br />
            Good luck with your search!!
          </Typography>
          <br />
          {
            (firebase.auth().currentUser == null) ?
              <Fragment>
                Het is noodzakelijk om ingelogd te zijn om mee te kunnen spelen!<br />
                You have to be logged in to play!
                <br/><br/>
                <Button variant="contained" href="/login">Login</Button>
              </Fragment>
              :
              <Fragment>
                <Button variant="contained" href={"/meet/" + this.state.meetno + "/qr_scan"}>SCAN</Button>
                <br/><br/>
                {this.renderList()}
              </Fragment>
          }
        </div>
      </div>
    );
  }

  renderList() {
    if (this.state.showLoading) {
      return (<CircularProgress/>);
    }

    let list = [];
    let qrCodesCount = this.state.meet.qrcode_count;

    //Check if there are QR Codes for this meet.
    if (qrCodesCount == null || qrCodesCount === 0) {
      return (<Typography variant="h6">Deze meet heeft geen QR-codes.<br />This meet does not have QR-codes</Typography>);
    }

    let shuffle = this.state.shuffle;
    let found = this.state.found;
    let answers = this.state.answers;
    //Make a list
    for (let i = 0; i < qrCodesCount; i++) {
      let isFound = (found != null && found[i] != null);
      let isAnswered = (answers != null && shuffle != null && answers[shuffle[i]] != null && answers[shuffle[i]].end_time != null);
      let txt = "QR " +
        (i + 1 /* index+1 = human readable */) +
        ((isFound) ? ((isAnswered) ? " - Done" : " - Answer this question") : " - QR-code not found yet");
      list.push(
        <ListItem button disabled={(!isFound)} component="a" href={"/meet/" + this.state.meetno + "/qr_quiz/" + i}>
          <ListItemIcon>
            {(isFound) ? ((isAnswered) ? <Done/> : <ModeComment/>) : <Search/>}
          </ListItemIcon>
          <ListItemText primary={txt}/>
        </ListItem>
      );
    }

    return (
      <Card>
        <List>
          {list}
        </List>
      </Card>
    );
  }

}

export default withStyles(styles)(QrHunt);
