import React, {Component} from 'react';
import '../App.css';
import * as firebase from "firebase";
import 'firebase/auth';
import 'firebase/database';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./Home";
import Meets from "./Meets";
import Meet from "./Meet";
import TopBar from "../components/TopBar";
import Login from "./Login";
import Info from "./Info";
import NotFound from "./NotFound";
import QrHunt from "./qrhunt/QrHunt";
import QrScan from "./qrhunt/QrScan";
import QrPrint from "./qrhunt/QrPrint";
import QrQuiz from "./qrhunt/QrQuiz";
import QrStats from "./qrhunt/QrStats";
import QrCheck from "./qrhunt/QrCheck";
import AddMeet from "./admin/AddMeet";
import QrCheck2 from "./qrhunt/QrCheck2";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {firebase: null};
  }

  render() {
    return (
      <div className="App">
        <TopBar/>
        <Router>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/login" component={Login}/>
            <Route path="/info" component={Info}/>
            <Route path="/meet/:no/qr_scan" component={QrScan}/>
            <Route path="/meet/:no/qr_print" component={QrPrint}/>
            <Route path="/meet/:no/qr_stats" component={QrStats}/>
            <Route path="/meet/:no/qr_check" component={QrCheck}/>
            <Route path="/meet/:no/qr_check2" component={QrCheck2}/>
            <Route path="/meet/:no/qr_quiz/:nr" component={QrQuiz} />
            <Route path="/meet/:no/qr/:code" component={QrScan}/>
            <Route path="/meet/:no/qr" component={QrHunt}/>
            <Route path="/meets" component={Meets}/>
            <Route path="/meet/:no" component={Meet}/>
            <Route path="/admin/addMeet" component={AddMeet} />
            <Route component={NotFound}/>
          </Switch>
        </Router>
      </div>
    );
  }

  componentWillMount() {
    //init Firebase
    this.initFirebase();
  }

  initFirebase() {
    try {
      // Initialize Firebase
      let config = {
        apiKey: "AIzaSyCbYjtZ3P-TuYdyb68YyZ79RipgG6Th6VU",
        authDomain: "frankkienl-broniesnl.firebaseapp.com",
        databaseURL: "https://frankkienl-broniesnl.firebaseio.com",
        projectId: "frankkienl-broniesnl",
        storageBucket: "frankkienl-broniesnl.appspot.com",
        messagingSenderId: "397704792981"
      };
      firebase.initializeApp(config);
      //
      //let firebaseApp = firebase.app();
      const firestore = firebase.firestore();
      console.log("Firebase loaded");
      //Let state know Firebase is loaded
      this.setState({
        firebase: {loaded: true}
      });
      //Add auth listener
    } catch (e) {
      console.error(e);
    }
  }

}

export default App;
